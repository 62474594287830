import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Layout from "./component/Layout";
import Login from "./component/Login";
import Dashboard from "./component/Dashboard";
import PrintAddress from "./component/PrintAddress";



import './App.css';


// import logo from './assets/images/logo.png';

function App() {


  return (
    <BrowserRouter>

      {/* <div className="loadr">
        <div className="loader_bg">
          <div className="loader_body_bg"></div>
          <div id="nest" className="loader_wait">
            <span>&nbsp;</span>
            </div>
            </div>
      </div> */}
      
      <Routes>
            <Route path="/dashboard" element={<Dashboard></Dashboard>} />,
            <Route path="/print-address" element={<PrintAddress></PrintAddress> } />
  
            <Route path="/" element={<Login />} />,
            <Route path="/login" element={<Login />} />
      
      </Routes>
    </BrowserRouter>

    
  );
}

export default App;
