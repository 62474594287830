import React ,{useState}from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from '../assets/images/manyavar.svg';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";




export default function Header() {
    const [isLoggedin, setIsLoggedin] = useState(false);
    if(!localStorage.getItem('userToken')){
        window.location = '/login';
        return false;
    }
    const logout = () => {
        localStorage.removeItem('userToken');
        localStorage.clear();
        setIsLoggedin(false);
        window.location = '/login';
    };
    return (
        <div>
            <nav className="navbar navbar-expand-sm bg-success navbar-dark">
                <Link to={'/dashboard'} className="navbar-brand" href="#"><img src={Logo} alt="logo" width={150}></img></Link>
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <Link  to={'/dashboard'} className="nav-link">Home</Link>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" onClick={(e) => { logout(e); }} >
						<span>Logout <FontAwesomeIcon icon={faSignOut} /></span> </a>
                    </li>
                </ul>
            </nav>
        </div>
    )
} 