import React from "react";
import '../assets/css/custom.css';




export default function Footer() {
    if(!localStorage.getItem('userToken')){
        window.location = '/login';
        return false;
    }
    return (
        <div>
            <footer className="sticky-footer bg-black">
                <div className="container my-auto">
                    <div className="copyright text-center my-auto" style={{color:"white"}}>
                        <span>Copyright © <a href="https://www.manyavar.com/" target="_blank">manyavar & Mohey</a> - 2023</span>
                    </div>
                </div>
            </footer>
        </div>
    )
} 