import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import '../assets/css/custom.css';




export default function Dashboard() {
    if(!localStorage.getItem('userToken')){
        window.location = '/login';
        return false;
    }
    // console.log(localStorage.getItem('user'));
    const mystyle = {
        justifyContent: 'center',
        alignItems: 'center',
      };
    return (
        <div>
            <Header></Header>
            <br></br>
            <div className="container">
                <div className="row justify-content-center">
                    {/* <div className="col-4"> */}
                        <div className="card" style={{ width: 400, textAlign:"center"}}>
                            <div className="card-header">
                            <img className="card-img-top" src="https://rr.manyavar.com/assets/images/print_address.png" alt="Card image" style={{opacity: 0.7}}></img>
                            </div>
                            <div className="card-body" style={{textAlign:"center"}}>
                                <Link to={'/print-address'}  className="btn btn-dark btn-flat print" >Print Address</Link>
                            </div>
                        </div>
                    {/* </div> */}
                </div>
            </div>
            <br></br>
            <Footer></Footer>
        </div>
    )
} 