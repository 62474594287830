import React, { useState } from 'react'
// import Logo from '../assets/images/logo.png'
// import { hideLoader, showLoader } from '../Services/common';
import Axios from 'axios';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import LogoImg from '../img/Manvayar_logo.webp';



export default function Login() {
	const [username, setUsername] = useState();
	const [password, setPassword] = useState();
	if (localStorage.getItem('userToken')) {
		localStorage.clear();
		return false;
	}
	const login = (e) => {
		if (username == null || password == null) {
			confirmAlert({
				title: 'Opps!!!',
				message: 'Please put user name and password to be continue...',
				buttons: [
					{
						label: 'Yes',
						onClick: () => window.location.reload()
					}
				]
			});
			return false;
		}

		/////Alert Working here///
		e.preventDefault();
		Axios.post("http://18.60.39.202:4001/get-token", {
			"user_id": "track-sample",
			"secret_key": "sample@2023",
		}).then((response) => {
			if (response.data.status === 'Success') {
				var token = response.data.token;
				Axios.post("http://18.60.39.202:4001/api/login", {
					username: username,
					password: password,
				}, {
					headers: {
						'authorization': `Bearer ${token}`
					}
				}).then((getResp) => {
					if (getResp.data.status === true) {
						localStorage.setItem('userToken', token);
						confirmAlert({
							title: 'Success',
							message: 'Are you Authenticated.',
							buttons: [
								{
									label: 'Yes',
									onClick: () => window.location = '/dashboard'
								}
							]
						});
					} else {
						confirmAlert({
							title: 'Opps!!!',
							message: getResp.data.message,
							buttons: [
								{
									label: 'Yes',
									onClick: () => window.location.reload()
								}
							]
						});
					}
				}).catch(function (err) {
					confirmAlert({
						title: 'Opps!!!',
						message: 'Our Internal server is busy now kindly try after some time...',
						buttons: [
							{
								label: 'Yes',
								onClick: () => window.location.reload()
							}
						]
					});
				})
			} else {
				confirmAlert({
					title: 'Opps!!!',
					message: "Authenticate Failed!!!",
					buttons: [
						{
							label: 'Yes',
							onClick: () => window.location.reload()
						}
					]
				});
			}
		}).catch(function (error) {
			confirmAlert({
				title: 'Opps!!!',
				message: 'Our Internal server is busy now kindly try after some time...',
				buttons: [
					{
						label: 'Yes',
						onClick: () => window.location.reload()
					}
				]
			});
		});
		return false;
	};

	return (
		<>
			<div className='center'>
				<img className="Auth-form-title" src={LogoImg}></img>
				<div className="card ">
					<form>
						<div className="card-header">Member Sign In</div>
						<div className="card-body">
							<div className="form-group mt-3">
								<label>User Name</label>
								<input type="text" className="form-control mt-1" placeholder="Enter User Name" required onChange={e => setUsername(e.target.value)} />
							</div>
							<div className="form-group mt-3">
								<label>Password</label>
								<input type="password" className="form-control mt-1" placeholder="Enter password" required onChange={e => setPassword(e.target.value)} />
							</div>
						</div>
						<div className="card-footer">
							<div className="d-grid gap-2 mt-2">
								<button className="btn btn-sm btn-danger" onClick={login}>Submit</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}
